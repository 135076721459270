@use 'src/src/app/scss/abstracts' as *;

$aligns: ('start', 'center', 'inherit', 'initial', 'unset', 'end', 'justify', 'left', 'right');

$weights: (
  'm': $font-weight-m,
  'l': $font-weight-l,
  'xl': $font-weight-xl,
);

$sizes: (
  '3xs': (
    small: (
      font-size: $font-size-m,
      line-height: $font-line-m,
      font-weight: $font-weight-l,
      letter-spacing: -0.36px,
    ),
    middle: (
      font-size: $font-size-m,
      line-height: $font-line-m,
      font-weight: $font-weight-l,
      letter-spacing: -0.36px,
    ),
    large: (
      font-size: $font-size-m,
      line-height: $font-line-m,
      font-weight: $font-weight-l,
      letter-spacing: -0.36px,
    ),
  ),
  'xs': (
    small: (
      font-size: $font-size-m,
      line-height: $font-line-m,
      font-weight: $font-weight-l,
      letter-spacing: -0.54px,
    ),
    middle: (
      font-size: $font-size-xl,
      line-height: $font-line-m,
      font-weight: $font-weight-l,
      letter-spacing: -0.743px,
    ),
    large: (
      font-size: $font-size-xl,
      line-height: $font-line-m,
      font-weight: $font-weight-l,
      letter-spacing: -0.743px,
    ),
  ),
  's': (
    small: (
      font-size: $font-size-s,
      line-height: $font-line-l,
      font-weight: $font-weight-m,
      letter-spacing: -0.32px,
    ),
    middle: (
      font-size: $font-size-2xl,
      line-height: $font-line-xl,
      font-weight: $font-weight-l,
      letter-spacing: -0.56px,
    ),
    large: (
      font-size: $font-size-2xl,
      line-height: $font-line-xl,
      font-weight: $font-weight-l,
      letter-spacing: -0.56px,
    ),
  ),
  '3l': (
    small: (
      font-size: $font-size-3l,
      line-height: $font-line-l,
      font-weight: $font-weight-m,
      letter-spacing: -0.32px,
    ),
    middle: (
      font-size: $font-size-3l,
      line-height: $font-line-xl,
      font-weight: $font-weight-l,
      letter-spacing: -0.56px,
    ),
    large: (
      font-size: $font-size-3l,
      line-height: $font-line-xl,
      font-weight: $font-weight-l,
      letter-spacing: -0.56px,
    ),
  ),
  'm': (
    small: (
      font-size: $font-size-s,
      line-height: $font-line-l,
      font-weight: $font-weight-m,
      letter-spacing: -0.32px,
    ),
    middle: (
      font-size: $font-size-3xl,
      line-height: $font-line-m,
      font-weight: $font-weight-l,
      letter-spacing: -1.08px,
    ),
    large: (
      font-size: $font-size-3xl,
      line-height: $font-line-m,
      font-weight: $font-weight-l,
      letter-spacing: -1.08px,
    ),
  ),
  'l': (
    small: (
      font-size: $font-size-3xl,
      line-height: $font-line-l,
      font-weight: $font-weight-xl,
      letter-spacing: -1.8px,
    ),
    middle: (
      font-size: $font-size-4xl,
      line-height: $font-line-l,
      font-weight: $font-weight-xl,
      letter-spacing: -0.96px,
    ),
    large: (
      font-size: $font-size-4xl,
      line-height: $font-line-l,
      font-weight: $font-weight-xl,
      letter-spacing: -0.96px,
    ),
  ),
  'xl': (
    small: (
      font-size: $font-size-3xl,
      line-height: $font-line-xs,
      font-weight: $font-weight-xl,
      letter-spacing: -1.8px,
    ),
    middle: (
      font-size: $font-size-6xl,
      line-height: $font-line-xs,
      font-weight: $font-weight-xl,
      letter-spacing: -3.4px,
    ),
    large: (
      font-size: $font-size-6xl,
      line-height: $font-line-xs,
      font-weight: $font-weight-xl,
      letter-spacing: -3.4px,
    ),
  ),
  '2xl': (
    small: (
      font-size: $font-size-5xl,
      line-height: $font-line-xs,
      letter-spacing: -3.78px,
      font-weight: $font-weight-xl,
    ),
    middle: (
      font-size: $font-size-6xl,
      line-height: $font-line-xs,
      font-weight: $font-weight-xl,
      letter-spacing: -3.4px,
    ),
    large: (
      font-size: $font-size-8xl,
      line-height: $font-line-2xs,
      letter-spacing: -9.1px,
      font-weight: $font-weight-xl,
    ),
  ),
  '3xl': (
    small: (
      font-size: $font-size-5xl,
      line-height: $font-line-xs,
      letter-spacing: -3.78px,
      font-weight: $font-weight-xl,
    ),
    middle: (
      font-size: $font-size-6xl,
      line-height: $font-line-xs,
      font-weight: $font-weight-xl,
      letter-spacing: -3.4px,
    ),
    large: (
      font-size: $font-size-9xl,
      line-height: $font-line-2xs,
      letter-spacing: -9.1px,
      font-weight: $font-weight-xl,
    ),
  ),
);
