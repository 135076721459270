@use 'src/src/app/scss/abstracts' as *;

@use './variables' as *;
@use './mixins' as *;

.title {
  $block-name: &;

  color: $color-semantic-third;
  font-family: $font-family-main;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: $font-weight-l;

  @each $key, $size in $sizes {
    &--size-#{$key} {
      @include fs(map-get($size, small), map-get($size, middle), map-get($size, large));

      &#{$block-name} {
        &--ellipsis-rows-two {
          @include ellipsis-rows(
            map-get($size, small),
            map-get($size, middle),
            map-get($size, large),
            $lines: 2
          );
        }

        &--ellipsis-rows-three {
          @include ellipsis-rows(
            map-get($size, small),
            map-get($size, middle),
            map-get($size, large),
            $lines: 3
          );
        }

        &--ellipsis-rows-fourth {
          @include ellipsis-rows(
            map-get($size, small),
            map-get($size, middle),
            map-get($size, large),
            $lines: 4
          );
        }
      }
    }
  }

  @each $align in $aligns {
    &--align-#{$align} {
      text-align: unquote($align);
    }
  }

  @each $key, $weight in $weights {
    &--weight-#{$key} {
      font-weight: $weight;
    }
  }

  &--color-main {
    color: $color-semantic-main;
  }

  &--color-inherit {
    color: inherit;
  }

  &--is-ellipsis {
    @include ellipsis;
  }

  &--is-italic {
    font-weight: 600;
    font-style: italic;
  }

  &--ellipsis-height-prop-height {
  }

  &--ellipsis-height-prop-max-height {
    height: auto !important;
  }
}
