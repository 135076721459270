@use 'src/src/app/scss/abstracts' as *;

@mixin fs($small, $middle, $large) {
  font-size: map-get($small, font-size);
  line-height: map-get($small, line-height);
  letter-spacing: map-get($small, letter-spacing);
  font-weight: map-get($small, font-weight);

  @include mq(md) {
    font-size: map-get($middle, font-size);
    line-height: map-get($middle, line-height);
    letter-spacing: map-get($middle, letter-spacing);
    font-weight: map-get($middle, font-weight);
  }

  @include mq(lg) {
    font-size: map-get($large, font-size);
    line-height: map-get($large, line-height);
    letter-spacing: map-get($large, letter-spacing);
    font-weight: map-get($large, font-weight);
  }
}

@mixin ellipsis-rows($small, $middle, $large, $lines: 2) {
  @include ellipsis-multiline(
    $font-size: map-get($small, font-size),
    $line-height: map-get($small, line-height),
    $lines-to-show: $lines
  );

  @include mq(md) {
    @include ellipsis-multiline(
      $font-size: map-get($middle, font-size),
      $line-height: map-get($middle, line-height),
      $lines-to-show: $lines
    );
  }

  @include mq(lg) {
    @include ellipsis-multiline(
      $font-size: map-get($large, font-size),
      $line-height: map-get($large, line-height),
      $lines-to-show: $lines
    );
  }
}
